export const Features = {
  basic: ['Multiple video formats', 'Text animations', 'Upload your own content'],
  pro: ['Multiple video formats', 'Text animations', 'Upload your own content'],
  custom: [
    'Everything in Pro',
    'Cost-efficient limited-time license',
    'Custom video templates',
    'Multilingual catalog integration',
    'Custom footage sourcing',
  ],
};

export const Comments = [
  {
    author: 'Epicentr K, Ukraine',
    text:
      '“Softcube provides us with stunning videos for various categories on our website. We highly recommend this platform!”',
    photo: '../img/epicentrk.png',
  },
  {
    author: 'SEM agency with over 750 Successful customers,  Germany',
    text:
      "“Softcube is a great partner, supporting us with high-quality videos that boost our performance!”",
    photo: '../img/Agency.png',
  },
  {
    author: 'Julieta Romero Gueto',
    text:
      "“Softcube allowed us to scale up our Facebook video ad campaigns, achieving consistently a low target CPA.”",
    photo: '../img/Julieta-Romero-Gueto.png',
  },
  {
    author: 'ALLO, Ukraine',
    text:
      "“It's easy to create eye-catching video ads using Softcube. Now we can scale our ad campaigns in a faster way.”",
    photo: '../img/ALLO.png',
  },
  {
    author: 'Epicentr K, Ukraine',
    text:
      '“Softcube provides us with stunning videos for various categories on our website. We highly recommend this platform!”',
    photo: '../img/epicentrk.png',
  },
  {
    author: 'SEM agency with over 750 Successful customers,  Germany',
    text:
      "“Softcube is a great partner, supporting us with high-quality videos that boost our performance!”",
    photo: '../img/Agency.png',
  },
  {
    author: 'Julieta Romero Gueto',
    text:
      "“Softcube allowed us to scale up our Facebook video ad campaigns, achieving consistently a low target CPA.”",
    photo: '../img/Julieta-Romero-Gueto.png',
  },
  {
    author: 'ALLO, Ukraine',
    text:
      "“It's easy to create eye-catching video ads using Softcube. Now we can scale our ad campaigns in a faster way.”",
    photo: '../img/ALLO.png',
  },
  {
    author: 'Epicentr K, Ukraine',
    text:
      '“Softcube provides us with stunning videos for various categories on our website. We highly recommend this platform!”',
    photo: '../img/epicentrk.png',
  },
  {
    author: 'SEM agency with over 750 Successful customers,  Germany',
    text:
      "“Softcube is a great partner, supporting us with high-quality videos that boost our performance!”",
    photo: '../img/Agency.png',
  },
];

export const QuestionsAnswersFirstColumn = [
  {
    question: 'Can I upgrade or downgrade my plan?',
    answer:
      'Yes, you can upgrade or downgrade your plan at any time. Whether you upgrade or downgrade, your unused video downloads for your current plan will be added to the allotment for your new plan. Unused video downloads will be available for a month if you’re switching from a plan that’s billed monthly or for a year if you’re switching from a plan that’s billed annually.',
  },
  {
    question: 'What if I want to download only one video ad?',
    answer:
      'Sorry, but we don’t offer single video plans. One video ad isn’t enough to run a successful marketing campaign. You need to be flexible and use different videos for different audiences. If you’re just getting started, we recommend you subscribe to our Basic plan.',
  },
  {
    question: 'Is it secure to pay online for Softcube services?',
    answer:
      'Yes, our online payment system enables you to make secure payments. We don’t save your credit card details.',
  },
];

export const QuestionsAnswersSecondColumn = [
  {
    question: 'How long will my unused video downloads be available?',
    answer:
      'If you have a monthly subscription plan, your video downloads must be used during the billing month. If you have an annual subscription plan, your video downloads will be available for the full billing year.',
  },
  {
    question: 'What is the difference between the annual and monthly plans?',
    answer:
      'When you choose a monthly plan, you will be billed monthly based on your initial subscription date. When you choose an annual plan, you will be billed once a year (also based on your initial subscription date).',
  },
  {
    question: 'Which payment methods do you accept?',
    answer: 'We accept all major credit cards.',
  },
];

export const QuestionsAnswersFirstColumnIndex = [
  {
    question: 'How does Softcube create video ads?',
    answer:
      'Softcube uses an artificial intelligence (AI) engine to generate video ads. First, it automatically sources videos related to your product or industry from the Softcube video library. Then it picks the best scenes from the most relevant videos, compiles those scenes, and adds text from your product listing page.',
  },
  {
    question: 'What content will Softcube use for my video ads?',
    answer:
      'Softcube uses video content created for various industries and  uploaded to the Softcube platform by contributors. These contributors hold exclusive rights to their content and earn a commission when their content is used in ads. We place a copyright mark on every video ad and deal with any DMCA notices.',
  },
  {
    question: 'How can I make an ad using Softcube?',
    answer:
      'Just choose a pricing plan and paste your product/listing URL. Our automatic ad maker will do the rest.',
  },
  {
    question: 'Where can I launch my Softcube video ads?',
    answer:
      'The most popular tools for launching video ads are Facebook Business Manager and Google Ads. Using Facebook Business Manager, you can show your ads on Facebook, Instagram, and the Audience Network. Google Ads allows you to launch YouTube advertising and show your ads on the Google Display Network. If you aren\'t sure which platform to choose, our guide will help you choose the right video advertising platform based on your goals.',
  },
  {
    question: 'How can I advertise on Facebook and Google?',
    answer:
      'To advertise on Facebook, create an ad account on Facebook Business Manager. To advertise on Google, create an ad account on Google Ads. On both platforms, you have to set up an ad campaign, create an ad set, and upload a video ad.',
  },
];

export const QuestionsAnswersSecondColumnIndex = [
  {
    question: 'How can I run video ads on YouTube?',
    answer:
      'To run YouTube video ads, create a Google Ads account, then link it to your YouTube account. Next, create a new ad campaign and in the "Campaign type" field select "Video". Adjust your campaign settings, create an ad group, and upload a video ad.',
  },
  {
    question: 'How can I upload a video to Facebook?',
    answer:
      'Download a video ad from Softcube. Then go to Facebook Ads Manager. Begin setting up your ad campaign and choose an objective that supports the video format. Next, create an ad set and create an ad. Finally, upload your video ad.',
  },
  {
    question: 'How can I run an Instagram ad?',
    answer: 'You can run an Instagram ad in two ways: by promoting a post directly in the Instagram app or by creating an ad in Facebook Ads Manager and choosing Instagram as the placement.',
  },
  {
    question: 'How much do Instagram ads cost?',
    answer: 'On average, Instagram CPC (cost per click) ads cost $0.70 per click. Note that you will pay between $0.20 and $2.00 per click depending on your industry, your target audience, the time of year, etc. ',
  },
  {
    question: 'I have questions. How can I contact you?',
    answer: 'Write to us at support@softcube.com or start a conversation in the live chat box in the bottom right corner of the website.',
  },
];
